import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from 'classnames'

import SideBar from "./sideBar"
import st from './css/header.module.scss'

const navs = [
  { path: '/', name: '首页' },
  { path: '/advance/', name: '优势' },
  { path: '/price/', name: '价格' },
  // { path: '/cases/', name: '客户案例' },
  { path: '/list/', name: '酷课文档' },
  { path: '/FQ.html', name: '常见问题' },
]
const Header = ({ cur, sticky }) => (
  <header className={classNames(st.header, { [st.sticky]: sticky })}>
    <Link className={st.logo} to='/'><b>服务预订、智慧经营</b></Link>
    <nav className={st.nav}>
      {
        navs.map(v => (<Link key={v.path} className={classNames(st.navItem, { [st.cur]: cur === v.path })} to={v.path}>{v.name}</Link>))
      }
    </nav>
    <SideBar />
  </header>
)

Header.propTypes = {
  cur: PropTypes.string,
}

Header.defaultProps = {
  cur: ``,
}

export default Header
